import logo from './morgan.svg';
import art1 from './Artboard – 7.svg';
import hero from './hero_bg.svg';
import art2 from './Artboard – 1.svg';
import art3 from './Artboard – 2.svg';
import art4 from './Artboard – 3.svg';
import art10 from './Artboard – 10.svg';
import art5 from './Artboard – 4.svg';
import art6 from './Artboard – 5.svg';
import art7 from './Artboard – 6.svg';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
    ChartBarIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

const stack = [
  {
    name: 'Mobile Development',
    description: 'Get a better understanding of where your leads are coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Data Visualization',
    description: 'Get a better understanding of where your leads are coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'UI Development',
    description: 'Get a better understanding of where your leads are coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Backend Development',
    description: 'Get a better understanding of where your leads are coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
]

const footerNavigation = {
  stack: [
    { name: 'Mobile Development', href: '#' },
    { name: 'Data Visualization', href: '#' },
    { name: 'UI Development', href: '#' },
    { name: 'Backend Development', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
  ],
  company: [
    { name: 'Products', href: '#' },
    { name: 'Solutions', href: '#' },
    { name: 'About Us', href: '#' },
    { name: 'Contact Us', href: '#' },
  ],
  legal: [
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    /*{
      name: 'Twitter',
      href: '#',
      icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
      ),
    },
    {
      name: 'GitHub',
      href: '#',
      icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                clipRule="evenodd"
            />
          </svg>
      ),
    },
    {
      name: 'Dribbble',
      href: '#',
      icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                clipRule="evenodd"
            />
          </svg>
      ),
    },*/
  ],
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function App() {
  return (
        <div className="bg-white">
          <header>
            <Popover className="relative bg-white">
              <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-10">
                <div className="flex lg:w-0 lg:flex-1">
                  <a href="/" className="mr-auto" >
                    <span className="sr-only">Morgan Mutugi</span>
                    <span className="text-3xl font-black font-serif text-gray-700 hover:text-gray-900">/<span className="text-sky-500">/</span>&nbsp;morgan.dev</span>
                  </a>
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Popover.Group as="nav" className="hidden md:flex space-x-10">
                  <Popover className="relative">
                    {({ open }) => (
                        <>
                          <Popover.Button
                              className={classNames(
                                  open ? 'text-gray-900' : 'text-gray-500',
                                  'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                              )}
                          >
                            <span>Stack</span>
                            <ChevronDownIcon
                                className={classNames(
                                    open ? 'text-gray-600' : 'text-gray-400',
                                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                                )}
                                aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-xs lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-1">
                                  {stack.map((item) => (
                                      <a
                                          key={item.name}
                                          href={item.href}
                                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                      >
                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-sky-400 to-cyan-500 text-white sm:h-12 sm:w-12">
                                          <item.icon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                        <div className="ml-4">
                                          <p className="text-base font-medium text-gray-900">{item.name}</p>
                                          <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                        </div>
                                      </a>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                    )}
                  </Popover>

                  <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Projects
                  </a>
                  <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Blog
                  </a>
                  <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                    Contact Me
                  </a>
                </Popover.Group>

                <div className="flex items-center border-l border-slate-200 ml-6 pl-6 dark:border-slate-800">
                  <label className="sr-only">Theme</label>
                  <button type="button" aria-haspopup="true" aria-expanded="false" aria-labelledby="button-theme">
                    <span className="dark:hidden">
                        <svg viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="w-6 h-6">
                            <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" className="fill-sky-400/20 stroke-sky-500"></path>
                            <path
                                d="M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836"
                                className="stroke-sky-500"></path>
                        </svg>
                    </span>
                    <span className="hidden dark:inline">
                      <svg viewBox="0 0 24 24" fill="none" className="w-6 h-6">
                          <path fillRule="evenodd" clipRule="evenodd"
                                d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z"
                                className="fill-sky-400/20"></path>
                          <path
                              d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z"
                              className="fill-sky-500"></path>
                          <path fillRule="evenodd" clipRule="evenodd"
                                d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z"
                                className="fill-sky-500"></path>
                      </svg>
                    </span>
                  </button>
                  <a href="https://github.com/morgan4080"
                     className="ml-6 block text-slate-400 hover:text-slate-500 dark:hover:text-slate-300">
                    <span className="sr-only">Morgan Mutugi on GitHub</span>
                    <svg viewBox="0 0 16 16" className="w-5 h-5" fill="currentColor" aria-hidden="true">
                      <path
                          d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
                    </svg>
                  </a>
                </div>

              </div>

              <Transition
                  as={Fragment}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                    focus
                    className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5">
                      <div className="flex items-center justify-between">
                        <div>
                          <img
                              className="h-8 w-auto"
                              src={logo}
                              alt="churpy-logo"
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-6">
                        <nav className="grid grid-cols-1 gap-7">
                          {stack.map((item) => (
                              <a
                                  key={item.name}
                                  href={item.href}
                                  className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                              >
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-sky-400 to-cyan-500 text-white">
                                  <item.icon className="h-6 w-6" aria-hidden="true" />
                                </div>
                                <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                              </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <div className="py-6 px-5">
                      <div className="grid grid-cols-2 gap-4">
                        <a href="/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                          Pricing
                        </a>
                        <a href="/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                          Pricing
                        </a>
                        <a href="/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                          Partners
                        </a>
                        <a href="/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                          Company
                        </a>
                      </div>
                      <div className="mt-6">
                        <a
                            href="/"
                            className="w-full flex items-center justify-center bg-gradient-to-r from-sky-400 to-cyan-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-purple-700 hover:to-sky-700"
                        >
                          Sign up
                        </a>
                        <p className="mt-6 text-center text-base font-medium text-gray-500">
                          Existing customer?
                          <a href="/" className="text-gray-900">
                            Sign in
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </header>

          <main>
            {/* Hero section */}
            <div className="relative pb-16">
              <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="relative sm:rounded-2xl sm:overflow-hidden sm:grid grid-cols-12">
                  <div className="sm:col-span-7">
                    <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:pt-24 lg:pb-8 lg:px-0">
                      <h1 className="text-left text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                        <span className="block font-cursive">Hello,</span>
                        <span className="block text-sky-500 font-cursive">I’m Morgan.</span>
                      </h1>
                      <p className="mt-6 max-w-lg text-left text-xl text-gray-700 sm:max-w-3xl">
                        Software developer.
                      </p>
                      <p className="mt-6 max-w-md text-left text-xl text-gray-700 sm:max-w-lg">
                        A fullstack developer, focused on user interfaces and apis.
                        I build software solutions for startups and improve existing
                        solutions to deliver value. I am building booking, e-commerce,
                        e-learning and other custom solutions for clients.
                      </p>

                      <button type="button" className="mt-6 text-center text-xl text-gray-700 bg-sky-500 text-white px-6 py-2">Read More</button>
                    </div>
                  </div>
                  <div className="sm:col-span-5 lg:pt-20">
                    <img className="w-full scale-150" src={hero} alt=""/>
                  </div>
                </div>
              </div>
            </div>

            {/* Alternating Feature Sections */}
            <div className="max-w-7xl mx-auto py-16 px-4 bg-white sm:px-6 lg:px-8">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight">
                Reconciliation nightmares are <br/> over and gone!
              </h2>
              <p className="mt-6 max-w-2xl text-lg text-gray-500">
                Our Reconciliation Engine offers you a smooth and elegant way to reconcile receivables in realtime by tracking
                multiple channels at the same time.
              </p>
              <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div className="relative p-8 bg-white border shadow-sm flex flex-col" style={{ background: '#193B59' }}>
                  <div className="flex-1">
                    <div className="bg-white -mr-10 mt-2 p-4">
                      <h6 className="text-sm font-semibold">Incoming payments</h6>
                      <div className="grid gap-2 grid-cols-12 mt-6">
                        <div className="col-span-2">
                          <img className="w-24 shadow-lg" src={art1} alt=""/>
                        </div>
                        <div className="col-span-8">
                          <p className="text-xs">Payment is identified</p>
                          <p className="text-xs font-semibold">04/05/22 | 9122 2829 ****</p>
                        </div>
                      </div>
                      <div className="grid gap-6 grid-cols-12 mt-16">
                        <div className="col-span-5">
                          <img className="w-full shadow-lg" src={art4} alt=""/>
                        </div>
                        <div className="col-span-5">
                          <img className="w-full shadow-lg" src={art10} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-white pt-20">
                    <h4 className="text-lg font-medium">Realtime payment tracking</h4>
                    <p className="mt-6 text-sm font-light">
                      Payments are detected as soon as they appear and routed to our A.I Reconciliation Engine.
                      <span className="opacity-0">and routed to our A.I Reconciliation Engine</span>
                    </p>
                  </div>
                </div>
                <div className="relative p-8 bg-white border shadow-sm flex flex-col" style={{ background: '#F2BF80' }}>
                  <div className="flex-1">
                    <div className="bg-white -ml-10 mt-2 px-6 py-4">
                      <h6 className="text-sm font-semibold">A.I Engine reconciles to invoice</h6>
                      <div className="grid gap-6 grid-cols-12 mt-3">
                        <div className="col-span-10">
                          <img className="w-full shadow-lg" src={art2} alt=""/>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white -mr-10 mt-2 p-4">
                      <h6 className="text-sm font-semibold">Realtime updates</h6>
                      <div className="grid gap-6 grid-cols-12 mt-3">
                        <div className="col-span-10">
                          <img className="w-full shadow-lg" src={art3} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-gray-900 pt-20">
                    <h4 className="text-lg font-medium">Intelligent Link-up</h4>
                    <p className="mt-6 text-sm font-light">
                      Churpy identifies matching invoices using A.I technology and updates your data in near realtime effectiveness.
                    </p>
                  </div>
                </div>
                <div className="relative p-8 bg-white border shadow-sm flex flex-col" style={{ background: '#50BFA0' }}>
                  <div className="flex-1">
                    <div className="bg-white -mr-10 mt-2 p-4">
                      <h6 className="text-sm font-semibold">Reconciliation reports</h6>
                      <div className="flex space-x-16 flex-wrap mt-2">
                        <div>
                          <img className="w-32 shadow-lg" src={art7} alt=""/>
                        </div>
                        <div className="-mt-8">
                          <img className="w-32 shadow-lg" src={art6} alt=""/>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div className="-mt-4 pr-2">
                          <img className="w-32 shadow-lg" src={art5} alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-white pt-20">
                    <h4 className="text-lg font-medium">Reports & Insights</h4>
                    <p className="mt-6 text-sm font-light">
                      We provide transparent detailed reports for various aspects of your data to suite decision making in all levels.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </main>

          <footer className="bg-gray-50" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
              Footer
            </h2>
            <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8">
              <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
                <div className="flex space-x-6 md:order-2">
                  {footerNavigation.social.map((item) => (
                      <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </a>
                  ))}
                </div>
                <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                  &copy; 2023 MORGAN. All rights reserved.
                </p>
              </div>
            </div>
          </footer>
        </div>
    );
}

export default App;
